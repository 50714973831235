import { NgModule } from "@angular/core";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { ManageBookingsPageRoutingModule } from "../routes/manage-bookings-page-routing.module";
import { HotelBookingItemModule } from "../../shared/components/booking-item/hotel-booking-item/hotel-booking-item.module";
import { FlightBookingItemModule } from "../../shared/components/booking-item/flight-booking-item/flight-booking-item.module";
import { CarBookingItemModule } from "../../shared/components/booking-item/car-booking-item/car-booking-item.module";
import { ManageBookingsPageComponent } from "../manage-bookings-page.component";
import { FlightsTermsDialogModule } from "../../shared/components/dialog/flights-terms-dialog/flights-terms-dialog.module";

@NgModule({
  imports: [
    SharedModule,
    ManageBookingsPageRoutingModule,
    HotelBookingItemModule,
    FlightBookingItemModule,
    CarBookingItemModule,
    FlightsTermsDialogModule,
    NgxSimpleDropdownModule,
  ],
  declarations: [
    ManageBookingsPageComponent,
  ],
})
export class SharedManageBookingsPageModule {}
