import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { FlightsTermsComponent } from "./flights-terms.component";
import { AccordionModule } from "booking-app-v2/shared/components/accordion/accordion.module";
import { BottomSheetWrapperModule } from "booking-app-v2/shared/components/bottom-sheet-wrapper/bottom-sheet-wrapper.module";
import { FlightsTermsDialogModule } from "../dialog/flights-terms-dialog/flights-terms-dialog.module";

@NgModule({
  declarations: [ FlightsTermsComponent ],
  imports: [
    SharedModule,
    AccordionModule,
    FlightsTermsDialogModule,
    BottomSheetWrapperModule,
  ],
  exports: [ FlightsTermsComponent ],
  providers: [],
})
export class FlightsTermsModule {}
