import { Injectable } from "@angular/core";
import { CanLoad, Router } from "@angular/router";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanLoad {

  constructor(private globalData: GlobalData, private router: Router) { }

  canLoad(): boolean {
    const isLoggedIn: boolean = this.globalData.isUserLoggedIn();
    if (!isLoggedIn) {
      this.router.navigate(["/"]);
    }
    return isLoggedIn;
  }
}
