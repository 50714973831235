import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { FareRule } from "booking-app-v2/flights/types";
import { FareRuleDialogContent } from "booking-app-v2/shared/types";

@Component({
  selector: "flights-terms-dialog",
  templateUrl: "/html/dialogs/flights_terms_dialog_v2",
})
export class FlightsTermsDialogComponent implements OnInit {
  fareRules: FareRule[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FareRuleDialogContent,
  ) { }

  ngOnInit() {
    this.fareRules = this.data.fareRules;
  }
}
