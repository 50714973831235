import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import {
  HotelBookingItemComponent,
} from "booking-app-v2/shared/components/booking-item/hotel-booking-item/hotel-booking-item.component";
import { SimpleDialogModule } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.module";
import {
  CancellationPolicyModule,
} from "booking-app-v2/shared/components/cancellation-policy/cancellation-policy.module";

@NgModule({
  declarations: [ HotelBookingItemComponent ],
  imports: [
    SharedModule,
    CancellationPolicyModule,
    SimpleDialogModule,
  ],
  exports: [ HotelBookingItemComponent ],
  providers: [],
})
export class HotelBookingItemModule {}
