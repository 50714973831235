import { Component, Input } from "@angular/core";

import { BookingItem } from "../booking-item";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BookingsService } from "booking-app-v2/shared/services/bookings.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { CarBooking } from "booking-app-v2/cars/models";

@Component({
  selector: "car-booking-item",
  templateUrl: "/html/account/car_booking_item_v2",
})
export class CarBookingItemComponent extends BookingItem {
  @Input() booking: CarBooking;

  constructor(
    appSettingsService: AppSettingsService,
    bookingsService: BookingsService,
    dialogService: DialogService,
    windowRefService: WindowRefService,
  ) {
    super(
      appSettingsService,
      bookingsService,
      dialogService,
      windowRefService,
    );
  }

  formatCarImgStyles(): { [klass: string]: any } {
    return {
      "background-image": `url(${this.booking.image_url}?w=400&auto=format&fm=pjpg&usm=11&usmrad=16&shad=15&dpr=1)`,
    };
  }

  ngOnInit(): void {
    this.initBookingItem();
  }
}
