import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { FlightCabinHelper, FlightSegment } from "booking-app-v2/flights/types";
import { TimeUtils } from "booking-app-v2/shared/utils";

@Component({
  selector: "flight-booking-segment",
  templateUrl: `/html/flights/booking_segment_v2`,
})
export class FlightBookingSegmentComponent {
  @Input() segment: FlightSegment;
  @Input() segments: FlightSegment[];
  @Input() index: number;

  constructor(
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
  ) { }

  arrivalDayOffset(): number {
    return TimeUtils.getDayOffset(this.segment.arrival_time, this.segment.departure_time);
  }

  displayArrivalDate(): boolean {
    return this.appSettingsService.flightSegmentArrivalDisplay && (this.arrivalDayOffset() > 0);
  }

  segmentArrivalDate(): string {
    return TimeUtils.localiseAndFormat(this.segment.arrival_time, "D MMM");
  }

  computedLayoverTime(index): string {
    const layoverDuration = TimeUtils.getTimeDuration(
        this.segment.departure_time,
        this.segments[index - 1].arrival_time,
    );
    return TimeUtils.formatDuration(layoverDuration);
  }

  airlineLogo(): string {
    return `/flights/${this.segment.marketing_airline_code}.png`;
  }

  flightIcon(): string {
    if (this.appSettingsService.customFlightIcon) {
      const tenant = this.appSettingsService.tenant.toLowerCase();
      return `/${tenant}/icons/icn-plane.png`;
    } else {
      return `/flight-icon.png`;
    }
  }

  flightCabin(): string {
    return this.translateService.instant(FlightCabinHelper.toStringTranslation(this.segment.cabin));
  }
}
