import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { FlightBookingSegmentComponent } from "./flight-booking-segment.component";

@NgModule({
  declarations: [ FlightBookingSegmentComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ FlightBookingSegmentComponent ],
  providers: [],
})
export class FlightBookingSegmentModule {}
