import { Component, Input, OnInit } from "@angular/core";

import { BookingItem } from "../booking-item";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BookingsService } from "booking-app-v2/shared/services/bookings.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { HotelBooking } from "booking-app-v2/hotels/models";
import { PointsPartner } from "booking-app-v2/shared/models";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

@Component({
  selector: "hotel-booking-item",
  templateUrl: "/html/account/hotel_booking_item_v2",
})
export class HotelBookingItemComponent extends BookingItem implements OnInit {
  @Input() booking: HotelBooking;

  isExpanded: boolean = false;

  readonly pointsPartner: PointsPartner;

  constructor(
    appSettingsService: AppSettingsService,
    bookingsService: BookingsService,
    dialogService: DialogService,
    windowRefService: WindowRefService,
    private globalData: GlobalData,
  ) {
    super(
      appSettingsService,
      bookingsService,
      dialogService,
      windowRefService,
    );
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
  }

  ngOnInit() {
    // Initializations shared by all travel types
    this.initBookingItem();
  }

  toggleDetailsExpand(): void {
    this.isExpanded = !this.isExpanded;
  }
}
