import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { FlightsTermsDialogComponent } from "./flights-terms-dialog.component";
import { AccordionModule } from "booking-app-v2/shared/components/accordion/accordion.module";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";

@NgModule({
  declarations: [ FlightsTermsDialogComponent ],
  imports: [
    SharedModule,
    AccordionModule,
  ],
  exports: [ FlightsTermsDialogComponent ],
  providers: [ DialogService ],
  entryComponents: [ FlightsTermsDialogComponent ],
})
export class FlightsTermsDialogModule {}
