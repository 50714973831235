import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { FlightBookingItemComponent } from "./flight-booking-item.component";
import { SimpleDialogModule } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.module";
import {
  FlightBookingSegmentModule,
} from "booking-app-v2/shared/components/flight-booking-segment/flight-booking-segment.module";
import { FlightsTermsModule } from "booking-app-v2/shared/components/flights-terms/flights-terms.module";

@NgModule({
  declarations: [ FlightBookingItemComponent ],
  imports: [
    SharedModule,
    FlightBookingSegmentModule,
    SimpleDialogModule,
    FlightsTermsModule,
  ],
  exports: [ FlightBookingItemComponent ],
  providers: [],
})
export class FlightBookingItemModule {}
