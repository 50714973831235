import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Config } from "@ascenda/ngx-simple-dropdown";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { BookingsService } from "booking-app-v2/shared/services/bookings.service";
import { BOOKING_FILTER, BookingFilter, Bookings } from "booking-app-v2/shared/types";
import { BookingFilterOption } from "booking-app-v2/shared/types/booking-filter-option.type";
import { Booking } from "booking-app-v2/shared/models";
import { HotelBooking } from "booking-app-v2/hotels/models";
import { CarBooking } from "booking-app-v2/cars/models";
import { FlightBooking } from "booking-app-v2/flights/models";

@Component({
  templateUrl: `/html/account/manage_bookings_v2`,
})
export class ManageBookingsPageComponent implements OnInit {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  filter: BookingFilter;
  bookings: Array<HotelBooking | CarBooking | FlightBooking>;
  pageIndex: number;
  maxPage: number;
  bookingSliceStartIndex: number;
  bookingSliceEndIndex: number;
  loading: boolean;
  filterDropdownConfig: Config<BookingFilterOption>;
  selectedFilterValue: string;

  private readonly _pageSize: number = 6;
  private readonly _filterTabTextMapping: { [bookingFilter in BookingFilter]?: string } = {
    [BOOKING_FILTER.ALL]: "accounts.bookings.label.all_bookings",
    [BOOKING_FILTER.UPCOMING]: "accounts.bookings.label.upcoming_bookings",
    [BOOKING_FILTER.COMPLETED]: "accounts.bookings.label.completed_bookings",
    [BOOKING_FILTER.CANCELLED]: "accounts.bookings.label.cancelled_bookings",
  };

  constructor(
    private globalData: GlobalData,
    private bookingsService: BookingsService,
    private translateService: TranslateService,
  ) {
    this.bookings = [];
  }

  ngOnInit() {
    this.queryBookings(BOOKING_FILTER.UPCOMING); // default query upcoming bookings
    this.initFilterDropdownConfig();
  }

  queryBookings(filter: BookingFilter): void {
    this.loading = true;
    this.filter = filter;
    this.bookingsService.getBookings(filter).subscribe((bookings: Bookings) => {
      this.bookings = bookings;
      this.pageIndex = 0;
      this.maxPage = Math.ceil(this.bookings.length / this._pageSize);
      this.updateBookingSliceIndex();
      this.loading = false;
    });
  }

  getFilterTabText(filter: BookingFilter): string {
    const filterTabTextKey: string = this._filterTabTextMapping[filter];
    return filterTabTextKey ? this.translateService.instant(filterTabTextKey) : "";
  }

  goNextPage(): void {
    if (this.pageIndex + 1 < this.maxPage) {
      this.pageIndex += 1;
      this.updateBookingSliceIndex();
    }
  }

  goPreviousPage(): void {
    if (this.pageIndex > 0) {
      this.pageIndex -= 1;
      this.updateBookingSliceIndex();
    }
  }

  trackByBookingId(index: number, booking: Booking): number {
    return booking.id;
  }

  private updateBookingSliceIndex(): void {
    this.bookingSliceStartIndex = this.pageIndex * this._pageSize;
    this.bookingSliceEndIndex = this.bookingSliceStartIndex + this._pageSize;
  }

  private initFilterDropdownConfig(): void {
    this.selectedFilterValue = this.translateService.instant(this._filterTabTextMapping[BOOKING_FILTER.UPCOMING]);
    const filterDropdownOption: BookingFilterOption[] = [];

    Object.keys(BOOKING_FILTER).map((optionId) => {
      filterDropdownOption.push({
        key: BOOKING_FILTER[optionId].toLocaleLowerCase(),
        label: this.translateService.instant(this._filterTabTextMapping[optionId.toLocaleLowerCase()]),
      });
    });

    this.filterDropdownConfig = {
      options: filterDropdownOption,
    };
  }
}
