import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { FareRule } from "booking-app-v2/flights/types";
import { COMPLEX_DIALOG } from "booking-app-v2/shared/types";
import { FlightsTermsDialogComponent } from "booking-app-v2/shared/components/dialog/flights-terms-dialog/flights-terms-dialog.component";

import { BottomSheetService } from "booking-app-v2/shared/services/bottom-sheet.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

@Component({
  selector: "flights-terms",
  template: `
    <a
      class="terms"
      [translate]="linkText"
      *ngIf="externalTermsConditionLink"
      [href]="externalTermsConditionLink"
      [target]="linkTarget"
    ></a>
    <a
      class="terms hidden-sm hidden-xs"
      [translate]="linkText"
      *ngIf="!externalTermsConditionLink"
      (click)="openDesktopModal()"
      href="javascript:;"
    ></a>
    <a
      class="terms hidden-lg hidden-md"
      [translate]="linkText"
      *ngIf="!externalTermsConditionLink"
      (click)="openMobileModal()"
      href="javascript:;"
    ></a>

    <ng-template #flightsTermsBottomSheet>
      <bottom-sheet-wrapper [bodyTopPadding]="8">
        <ng-container bottomSheetTitle>
          <span class="heading" [translate]="getBottomSheetTitle()"></span>
        </ng-container>
        <ng-container bottomSheetBody>
          <p class="loading" *ngIf="!fareRules" translate="Terms & Conditions is loading..."></p>
          <p align="center" *ngIf="fareRules?.length === 0" translate="No terms & conditions available"></p>
          <accordion class="accordion-element" *ngFor="let fareRule of fareRules">
            <span accordionTitle [textContent]="fareRule.formattedCategory"></span>
            <pre accordionContent [translate]="fareRule.rule"></pre>
          </accordion>
        </ng-container>
      </bottom-sheet-wrapper>
    </ng-template>
  `,
})
export class FlightsTermsComponent implements OnInit {
  @Input() linkText: string;
  @Input() fareRules: FareRule[];
  @Input() externalTermsConditionLink: string;
  @ViewChild("flightsTermsBottomSheet") flightsTermsBottomSheetTemplate: TemplateRef<any>;

  linkTarget: string;
  customTitle: string;

  constructor(
    private appSettingsService: AppSettingsService,
    private bottomSheetService: BottomSheetService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.linkText = this.linkText || "Read airfare terms and conditions";
    this.linkTarget = this.appSettingsService.openInTargetTab.allLinksTarget;
    this.customTitle = this.appSettingsService.customFlightsTermsAndConditionsModalTitle;
    this.fareRules.forEach((fareRule: FareRule) => {
      fareRule.formattedCategory = this.formatCategory(fareRule.category);
    });
  }

  openDesktopModal(): void {
    this.dialogService.open(COMPLEX_DIALOG.FLIGHTS_TERMS, FlightsTermsDialogComponent, { fareRules: this.fareRules });
  }

  openMobileModal(): void {
    this.bottomSheetService.open(this.flightsTermsBottomSheetTemplate);
  }

  getBottomSheetTitle(): string {
    return this.customTitle || "Terms & Conditions";
  }

  private formatCategory(category: string): string {
    const translatedString: string = this.translateService.instant(category);
    if (translatedString === category) {
      return category.replace(/_/g, " ");
    }
    return translatedString;
  }
}
