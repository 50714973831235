import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { map, Observable, startWith, take, tap } from "rxjs";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CountryService } from "booking-app-v2/shared/services/country.service";
import { UserService } from "booking-app-v2/shared/services/user.service";

import { User } from "booking-app-v2/shared/models";
import {
  ApiDataError,
  Country,
  GlobalDataEnum,
  State,
  UpdatePasswordParams,
  UpdateProfileParams,
} from "booking-app-v2/shared/types";
import { passwordMismatchValidator, weakPasswordValidator } from "booking-app-v2/shared/validators";

@Component({
  templateUrl: "/html/account/profile_v2",
})
export class ProfilePageComponent implements OnInit {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  profileForm: FormGroup;
  passwordForm: FormGroup;
  marketingPreferenceForm: FormGroup;
  filteredCountryOptions$: Observable<Country[]>; // countries rendered in views
  filteredStateOptions$: Observable<State[]>; // states rendered in views
  currentUserCountry: Country; // DB's value of user country
  currentUserState: State; // DB's value of user state
  currentUser: User;

  readonly birthdayMaxDate: Date = new Date();
  readonly countryDisplayFn = (country: Country) => country?.name ?? "";
  readonly stateDisplayFn = (state: State) => state?.name ?? "";

  constructor(
    public globalData: GlobalData,
    private translateService: TranslateService,
    private appSettingsService: AppSettingsService,
    private countryService: CountryService,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) {
    this.currentUser = this.globalData.get(GlobalDataEnum.CURRENT_USER);
  }

  ngOnInit() {
    this.initUserCountryState();
    this.initProfileForm();
    this.initPasswordForm();
    this.initMarketingPreferenceForm();
  }

  toggleEditProfileForm(): void {
    if (this.profileForm.disabled) {
      this.profileForm.enable();
    } else {
      this.profileForm.disable();
      this.initProfileForm();
    }
  }

  toggleEditPasswordForm(): void {
    if (this.passwordForm.disabled) {
      this.passwordForm.enable();
    } else {
      this.passwordForm.disable();
      this.initPasswordForm();
    }
    if (this.profileForm.enabled) {
      this.toggleEditProfileForm();
    }
  }

  logForm() {
    this.submitProfileForm();
    this.submitPasswordForm();
  }

  submitProfileForm(): void {
    if (this.profileForm.invalid) {
      return;
    }
    const profileFormControls: { [key: string]: AbstractControl } = this.profileForm.controls;
    const birthday: Date = profileFormControls.birthday.value;
    const updateProfileParams: UpdateProfileParams = { user: {
      first_name: profileFormControls.firstName.value,
      last_name: profileFormControls.lastName.value,
      address1: profileFormControls.address.value,
      city: profileFormControls.city.value,
      zip_code: profileFormControls.zipCode.value,
      country: profileFormControls.country.value?.code ?? null,
      state: profileFormControls.state.value?.code ?? null,
      day: birthday ? birthday.getDate() : null,
      month: birthday ? birthday.getMonth() + 1 : null,
      year: birthday ? birthday.getFullYear() : null,
      company: profileFormControls.company.value,
      company_details: profileFormControls.companyDetails.value,
      title: profileFormControls.title.value ?? null,
    }};
    this.userService.update(updateProfileParams).subscribe({
      next: (updatedRawUser: User) => {
        const updatedUser: User = new User(this.appSettingsService, this.globalData, updatedRawUser);
        this.globalData.set(GlobalDataEnum.CURRENT_USER, updatedUser);
        this.currentUser = updatedUser;
        this.currentUserCountry = profileFormControls.country.value;
        this.currentUserState = profileFormControls.state.value;
        this.displayAlertMessage(true);
        this.toggleEditProfileForm();
      },
      error: () => this.displayAlertMessage(false),
    });
  }

  submitPasswordForm(): void {
    if (this.passwordForm.invalid) {
      return;
    }
    const passwordFormControls: { [key: string]: AbstractControl } = this.passwordForm.controls;
    const updatePasswordParams: UpdatePasswordParams = { user: {
      current_password: passwordFormControls.currentPassword.value,
      password: passwordFormControls.newPassword.value,
      password_confirmation: passwordFormControls.confirmNewPassword.value,
    }};
    this.userService.updatePassword(updatePasswordParams).subscribe({
      next: () => {
        this.displayAlertMessage(true);
        this.toggleEditPasswordForm();
      },
      error: (err: ApiDataError) => this.displayAlertMessage(false, err.errors[0]),
    });
  }

  submitMarketingPreferenceForm(): void {
    const updateProfileParams: UpdateProfileParams = { user: {
      send_marketing: this.marketingPreferenceForm.controls.marketingPreference.value,
    }};
    this.userService.update(updateProfileParams).subscribe({
      next: (updatedRawUser: User) => {
        const updatedUser: User = new User(this.appSettingsService, this.globalData, updatedRawUser);
        this.globalData.set(GlobalDataEnum.CURRENT_USER, updatedUser);
        this.displayAlertMessage(true);
      },
      error: () => this.displayAlertMessage(false),
    });
  }

  private initProfileForm(): void {
    this.profileForm = new FormGroup({
      title: new FormControl(this.currentUser.title),
      firstName: new FormControl(this.currentUser.first_name, Validators.required),
      lastName: new FormControl(this.currentUser.last_name),
      birthday: new FormControl(this.currentUserBirthdayAsDate()),
      address: new FormControl(this.currentUser.address1),
      city: new FormControl(this.currentUser.city),
      zipCode: new FormControl(this.currentUser.zip_code),
      country: new FormControl(this.currentUserCountry),
      state: new FormControl(this.currentUserState),
      company: new FormControl(this.currentUser.company),
      companyDetails: new FormControl(this.currentUser.company_details),
    });
    this.profileForm.disable();
    this.buildFilteredCountriesList();
  }

  private buildFilteredCountriesList(): void {
    this.filteredCountryOptions$ = this.profileForm.controls.country.valueChanges.pipe(
      startWith(this.profileForm.controls.country.value),
      tap((formControlValue) => {
        if (!formControlValue || typeof formControlValue === "string" || this.profileForm.disabled) {
          return;
        }

        if (!this.countryService.isStateRequired(formControlValue.code)) {
          this.profileForm.controls.state.setValue(undefined);
          setTimeout(() => this.profileForm.controls.state.disable());
          return;
        }

        this.profileForm.controls.state.enable();
        this.countryService.getStates(formControlValue.code).pipe(take(1)).subscribe((states: State[]) => {
          this.buildFilteredStatesList(states);
        });
      }),
      map((formControlValue: Country | string) => {
        const countryName = this.countryService.getNameFromFormControlValue(formControlValue);
        return this.countryService.filteredCountriesList(countryName);
      }),
    );
  }

  private buildFilteredStatesList(statesList: State[]): void {
    this.filteredStateOptions$ = this.profileForm.controls.state.valueChanges.pipe(
      startWith(this.profileForm.controls.state.value),
      map((formControlValue: State | string) => {
        const stateName = this.countryService.getNameFromFormControlValue(formControlValue);
        return this.countryService.filteredStatesList(statesList, stateName);
      }),
    );
  }

  private initPasswordForm(): void {
    this.passwordForm = new FormGroup({
      currentPassword: new FormControl("", Validators.required),
      newPassword: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        weakPasswordValidator(/^(?=.*[A-Z])(?=.*[\W_])(?=.*[0-9])(?=.*[a-z]).{8,}$/),
      ]),
      confirmNewPassword: new FormControl("", Validators.required),
    }, { validators: passwordMismatchValidator });
    this.passwordForm.disable();
  }

  private initMarketingPreferenceForm(): void {
    this.marketingPreferenceForm = new FormGroup({
      marketingPreference: new FormControl(this.currentUser.send_marketing || false),
    });
  }

  private initUserCountryState(): void {
    this.currentUserCountry = this.countryService.getCountry(this.currentUser.country);
    this.currentUserState = null;
    if (this.currentUserCountry && this.currentUser.state?.length > 0) {
      this.countryService.getState(this.currentUser.country, this.currentUser.state).subscribe((state: State) => {
        this.currentUserState = state;
        this.profileForm.controls.state.setValue(state);
      });
    }
  }

  private displayAlertMessage(success: boolean, message?: string): void {
    const defaultMessage: string = success
      ? "Your profile has been updated."
      : "There was an error updating your profile. Please try again.";
    const alertMessage: string = this.translateService.instant(message || defaultMessage);

    this.snackBar.open(alertMessage, "Dismiss", {
      panelClass: ["profile", success ? "success" : "failed"],
      duration: 5000,
    });
  }

  private currentUserBirthdayAsDate(): Date {
    const birthday: Date = new Date(this.currentUser.year, this.currentUser.month - 1, this.currentUser.day);
    return isNaN(birthday.getTime()) ? null : birthday;
  }
}
