import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { ProfilePageComponent } from "../profile-page.component";
import { ProfilePageRoutingModule } from "../routes/profile-page-routing.module";

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ProfilePageRoutingModule,
    NgxSimpleDropdownModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
  ],
  declarations: [
    ProfilePageComponent,
  ],
})
export class SharedProfilePageModule {}
