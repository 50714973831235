import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { profilePageRoutes } from "./profile-page.routes";

@NgModule({
  imports: [
    RouterModule.forChild(profilePageRoutes),
  ],
  exports: [RouterModule],
})
export class ProfilePageRoutingModule { }
