import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BookingsService } from "booking-app-v2/shared/services/bookings.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { SimpleDialogComponent } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.component";
import { Booking } from "booking-app-v2/shared/models";
import { SIMPLE_DIALOG } from "booking-app-v2/shared/types";

export class BookingItem {
  cancelStatus: "cancellable" | "cancelling" | "cancelled" | "error";
  partnerPointLabel: string;
  partnerLogoUrl: string;
  booking: Booking;

  constructor(
    public appSettingsService: AppSettingsService,
    public bookingsService: BookingsService,
    public dialogService: DialogService,
    public windowRefService: WindowRefService,
  ) {
    this.cancelStatus = "cancellable";
  }

  initBookingItem(): void {
    if (this.booking.points_credits?.length > 0) {
      this.partnerPointLabel = this.booking.points_credits[0].points_partner.currency_short;
      this.partnerLogoUrl = this.booking.points_credits[0].points_partner.logo_url;
    }
  }

  resendConfirmationEmail(): void {
    this.bookingsService.sendConfirmationEmail(this.booking.id)
      .subscribe({
        next: () => this.dialogService.open(SIMPLE_DIALOG.RESEND_CONFIRMATION_SUCCESS, SimpleDialogComponent),
        error: () => this.dialogService.open(SIMPLE_DIALOG.RESEND_CONFIRMATION_FAILED, SimpleDialogComponent),
      });
  }

  cancelBooking(): void {
    // Open modal to ask user to confirm cancel booking
    this.dialogService.open(SIMPLE_DIALOG.CANCEL_BOOKING, SimpleDialogComponent).subscribe((confirm) => {
      if (!confirm) {
        return;
      }
      // If user confirms, start processing and open cancelling booking modal
      this.cancelStatus = "cancelling";
      this.dialogService.open(SIMPLE_DIALOG.CANCELLING_BOOKING, SimpleDialogComponent);
      this.bookingsService.cancelBooking(this.booking.id)
        .subscribe({
          next: (result) => {
            // If booking cancelled successfully, open cancel success modal, page reloads when user clicks OK
            this.cancelStatus = "cancelled";
            this.dialogService.open(SIMPLE_DIALOG.CANCEL_BOOKING_SUCCESS, SimpleDialogComponent).subscribe(() => {
              this.windowRefService.nativeWindow.location.reload();
            });
          },
          error: (error) => {
            // If booking cancelled successfully, open cancel failed modal, page reloads when user clicks OK
            this.cancelStatus = "error";
            this.dialogService.open(SIMPLE_DIALOG.CANCEL_BOOKING_FAILED, SimpleDialogComponent).subscribe(() => {
              this.windowRefService.nativeWindow.location.reload();
            });
          },
        });
    });
  }
}
