import { Component, Input } from "@angular/core";

import { BookingItem } from "../booking-item";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BookingsService } from "booking-app-v2/shared/services/bookings.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { SimpleDialogComponent } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.component";
import { FlightBooking } from "booking-app-v2/flights/models";
import { SIMPLE_DIALOG } from "booking-app-v2/shared/types";

@Component({
  selector: "flight-booking-item",
  templateUrl: "/html/account/flight_booking_item_v2",
})
export class FlightBookingItemComponent extends BookingItem {
  @Input() booking: FlightBooking;

  constructor(
    appSettingsService: AppSettingsService,
    bookingsService: BookingsService,
    dialogService: DialogService,
    windowRefService: WindowRefService,
  ) {
    super(
      appSettingsService,
      bookingsService,
      dialogService,
      windowRefService,
    );
  }

  cancelBookingRequest(): void {
    // Open modal to ask user to confirm send cancel booking request
    this.dialogService.open(SIMPLE_DIALOG.CANCEL_BOOKING_REQUEST, SimpleDialogComponent).subscribe(() => {
      // If user confirms, start processing
      this.cancelStatus = "cancelling";
      this.bookingsService.cancelBookingRequest(this.booking.id)
        .subscribe({
          next: (result) => {
            // If booking cancelled successfully, open cancel request success modal
            this.cancelStatus = "cancelled";
            this.dialogService.open(SIMPLE_DIALOG.CANCEL_BOOKING_REQUEST_SUCCESS, SimpleDialogComponent);
          },
          error: (error) => {
            // If booking cancelled failed, open cancel request failed modal
            this.cancelStatus = "error";
            this.dialogService.open(SIMPLE_DIALOG.CANCEL_BOOKING_REQUEST_FAILED, SimpleDialogComponent);
          },
        });
    });
  }

}
